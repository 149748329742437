<template>
    <div v-if="showLogin">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <LoginForm @login="enterDashboard"/> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'

export default {
    components: { LoginForm },
    setup(){
        const showLogin = ref(true)
        const router = useRouter()
        const route = useRoute()

        const enterDashboard = () => {
            if (route.query.lesson){
                router.push({ name: 'Lesson', params: {course:route.query.course, lesson: route.query.lesson}})
            } else {
                window.top.location.href = 'https://dimitriskamaritis.notion.site/87098f52a6dd4ae68c581b8fc1599404?v=bd90a73eb2a24b20b03856b3139ff3cd'
            }
        }

        return { showLogin, enterDashboard }
    }
}
</script>