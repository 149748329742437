<template>
    <form @submit.prevent="handleSubmit">
        <div class="mb-3">
            <label class="form-label">Email address</label>
            <input type="email" required class="form-control" v-model="email">
        </div>
        <div class="mb-3">
            <label class="form-label">Password</label>
            <input type="password" required class="form-control" v-model="password">
        </div>
        <div>{{error}}</div>
        <button class="btn btn-primary">Login</button>
    </form>
</template>

<script>

import {ref} from 'vue'
import useLogin from '../composables/useLogin'

export default {
    setup(props, context){

        //refs
        const email = ref('')
        const password = ref('')

        const { error, login } = useLogin()

        const handleSubmit = async () => {
            await login(email.value, password.value)
            if (!error.value) {
                console.log('user logged in')
                context.emit('login')
            }
        }

        return {email, password, handleSubmit, error}
    }
}
</script>

<style>

</style>