import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Buy from '../views/Buy.vue'
import Lesson from '../views/Lesson.vue'
import NotFound from '../views/NotFound.vue'
import { projectAuth } from '../firebase/config'
import { projectFirestore } from '../firebase/config'

//auth guard
const requireAuth = (to, from, next) => {

  let user = projectAuth.currentUser

  if (!user){
    next({ name: 'Login' })
  } else {
    next()
  }
  
}

const requireAuthLesson = async (to, from, next) => {

  let user = projectAuth.currentUser

  if (!user){
    // (not) logged in + trying to access lesson
    next({ name: 'Buy', query: { course: to.params.course, lesson: to.params.lesson } })
  } 
  
  else {
    // logged in + trying to access lesson
    let res = await projectFirestore.collection('users').doc(user.uid).get()
    let user_data = res.data()

    if((typeof user_data.membership !== 'undefined') && (user_data.membership === 'annual')) {
      // if user is a member
      next()
    } 
    
    else if (typeof user_data.courses !== 'undefined' && user_data.courses.length > 0){
      if (user_data.courses.includes(to.params.course)){
        // if user has purchased the course
        next()
      } else {
        // if user has not purchased the course
        next({ name: 'Buy', query: { course: to.params.course, lesson: to.params.lesson } })
      }
    }
    
    else {
      next({ name: 'Buy', query: { course: to.params.course, lesson: to.params.lesson } })
    }
  }
}

const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (user){
    next({ name: 'Dashboard' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard, 
    beforeEnter: requireAuth
  },
  {
    path: '/courses/:course/lessons/:lesson',
    name: 'Lesson',
    component: Lesson, 
    beforeEnter: requireAuthLesson,
    props: true
  },
  {
    path: '/buy',
    name: 'Buy',
    component: Buy
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
