import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDNBzNsblB6HgYzcH1o9ttEHgd7xR99yWU",
    authDomain: "absolute-router-313510.firebaseapp.com",
    projectId: "absolute-router-313510",
    storageBucket: "absolute-router-313510.appspot.com",
    messagingSenderId: "813408151625",
    appId: "1:813408151625:web:8629182f7922ee4ecef798"
};

//init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, timestamp }