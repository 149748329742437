import {ref} from 'vue'
import { projectFirestore } from '../firebase/config'

const getCourse = (course_id) => {
    const course = ref(null)
    const error = ref(null)

    const load = async () => {
      try {
        
        let res = await projectFirestore.collection('courses').doc(course_id).get()
        if(!res.exists){
          throw Error('Course does not exist.')
        }

        course.value = {id: res.id, ...res.data()}
        

      } catch(err){
        error.value = err.message
      }
    }

    return {course, error, load}
}

export default getCourse