<template>
  <nav v-if="user">
      <div>
          <h3>Hey there {{user.displayName}}!</h3>
          <p>Currently logged in as {{ user.email }}</p>
          <button class="btn btn-primary" @click="handleClick">Logout</button>
      </div>
  </nav>
</template>

<script>
import useLogout from '../composables/useLogout'
import getUser from '../composables/getUser'
import { useRouter } from 'vue-router'

export default {

  setup(){

    const { logout, error } = useLogout()
    const { user } = getUser()
    const router = useRouter()

    const handleClick = async () => {
      await logout()
      if(!error.value){
        console.log('user logged out')
        router.push({ name: 'Login' })
      }
    }

    return { handleClick, user }
  }

}
</script>

<style>

</style>