<template>
  <div>
    <div v-if="error">
        <h3>{{error}}</h3>
    </div>
    <div v-if="lesson">
        <div style="position: relative; padding-top: 55.0351288056206%;">
          <iframe :src='lesson.video_link' loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
        </div>
    </div>
  </div>  
</template>

<script>

import Navbar from '../components/Navbar.vue'
import getLesson from '../composables/getLesson'

export default {
  props: ['course', 'lesson'],
  components: { Navbar },
  setup(props){

    const { lesson, error, load } = getLesson(props.course, props.lesson)

    load()

    return { lesson, error }
  }
}
</script>