<template>
    <div>
        <h2>This page does not exist :(</h2>
        <h3><a class='btn btn-primary' href='https://dimitriskamaritis.notion.site/Automation-Academy-40cf30e875bf433ea5314eac08cbc977'>Back to home</a></h3>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'

export default {
    components: { Navbar }
}
</script>