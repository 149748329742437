<template>
    <div v-if="error">
        <h3>{{error}}</h3>
    </div>
    <div class="row">
        <div v-if="course">
            <div class="col" v-if="course.gumroad_code">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">Course</div>
                        <button class="btn btn-primary" @click="buyCourse">Buy {{course.title}} !</button>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">Membership</div>
                        <button class="btn btn-primary" @click="buyMembership">Become A Member</button>
                    </div>
                </div>
            </div>
            <div class="col" v-if="showLogin">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">Login</div>
                        <button class="btn btn-primary" @click="enterLogin">Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import getCourse from '../composables/getCourse'
import { projectAuth } from '../firebase/config'

export default {
    setup(){
        const router = useRouter()
        const route = useRoute()
        const showLogin = ref(false)

        const { course, error, load } = getCourse(route.query.course)

        load()

        onMounted(() => {
            let user = projectAuth.currentUser
            if(!user){
                showLogin.value = true
            }
        })

        // ACTIONS
        const buyMembership = () => {
            window.top.location.href = 'https://dimitriskamaritis.notion.site/Become-A-Member-2e9d56dcb5de45c7af51ab9398b951bb'
        }

        const buyCourse = () => {
            window.top.location.href = `https://dkamaritis.gumroad.com/l/${course.value.gumroad_code}`
        }

        const enterLogin = () => {
            if (route.query.lesson){
                router.push({ name: 'Login', query: {course:route.query.course, lesson: route.query.lesson}})
            } else {
                router.push({ name: 'Login'})
            }
        }

        return { showLogin, buyCourse, buyMembership, course, enterLogin, error }
    }
}
</script>