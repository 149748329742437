import {ref} from 'vue'
import { projectFirestore } from '../firebase/config'

const getLesson = (course_id, lesson_id) => {
    const lesson = ref(null)
    const error = ref(null)

    const load = async () => {
      try {
        
        let res = await projectFirestore.collection('courses').doc(course_id).collection('lessons').doc(lesson_id).get()
        if(!res.exists){
          throw Error('Lesson does not exist.')
        }
        let video_link = `https://iframe.mediadelivery.net/embed/6323/${res.data().video_id}?autoplay=false`

        lesson.value = {id: res.id, video_link: video_link}

      } catch(err){
        error.value = err.message
      }
    }

    return {lesson, error, load}
}

export default getLesson